jQuery(function($) {
    $('.gm-map-view').each(function(){
        Show_Map_By_Coordinates($(this));
    });
});

function Show_Map_By_Coordinates($map_view, $map_type){
    var $ = jQuery;
    if( !$map_view ){
        $map_view = $('.gm-map-view');
    }

    if($map_view.length == 0)
        return;

    var $map;
    var $directionsDisplay;
    var $directionsService;
    var $map_position = new google.maps.LatLng(51.517773, 0.066605);
    var $map_address = $map_view.data('address');
    var $map_marker = $map_view.data('marker');
    var $map_zoom = $map_view.data('zoom')?$map_view.data('zoom'):15;
    var $map_styles = $map_view.data('styles')?$map_view.data('styles'):false;
    var $delay_load = $map_view.data('delay')?$map_view.data('delay'):false;

    if(!$map_type)
        $map_type = google.maps.MapTypeId.ROADMAP;

    function initialize() {
        create_map();
    }

    function create_map(){

        if($map_view.data('latitude') && $map_view.data('longitude')) {
            $map_position = new google.maps.LatLng($map_view.data('latitude'), $map_view.data('longitude') );
        }
        else if( $map_address ){
            var $geocoder = new google.maps.Geocoder();
            $geocoder.geocode( { 'address': $map_address}, function(results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    setCenter(results[0].geometry.location);
                    $map.setCenter(results[0].geometry.location);
                    //$map.panBy(-320,0);
                }
            });
        }

//        var $styles = [ { "featureType": "poi", "elementType": "geometry.fill", "stylers": [ { "color": "#ddddda" } ] },{ "featureType": "landscape", "stylers": [ { "color": "#ecebeb" } ] },{ "featureType": "poi", "elementType": "labels.icon", "stylers": [ { "saturation": -98 }, { "lightness": 16 }, { "gamma": 0.85 } ] },{ "elementType": "geometry.stroke", "stylers": [ { "color": "#d8d8d8" } ] },{ "featureType": "road.highway", "stylers": [ { "color": "#f8f6f9" } ] },{ "featureType": "water", "stylers": [ { "color": "#cecccc" } ] },{ "featureType": "transit.station", "stylers": [ { "visibility": "off" } ] } ];



        var $mapOptions = {
            styles: $map_styles,
            zoom: $map_zoom,
            center: $map_position,
            mapTypeId: $map_type,
            scrollwheel: false
        };

        $map = new google.maps.Map($map_view.get(0), $mapOptions);

        setCenter($map_position);

        $directionsService = new google.maps.DirectionsService();
        $directionsDisplay = new google.maps.DirectionsRenderer();
        $directionsDisplay.setMap($map);
        //$map.panBy(-320,0);
    }

    function setCenter($position){
        var pinColor = "96003D";
        var pinImage = new google.maps.MarkerImage("http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|" + pinColor,
            new google.maps.Size(21, 34),
            new google.maps.Point(0,0),
            new google.maps.Point(10, 34));
        var pinShadow = new google.maps.MarkerImage("http://chart.apis.google.com/chart?chst=d_map_pin_shadow",
            new google.maps.Size(40, 37),
            new google.maps.Point(0, 0),
            new google.maps.Point(12, 35));
        var $center_marker = new google.maps.Marker({
            position: $position,
            map: $map,
            icon: $map_marker
            //shadow: pinShadow
        });
    }

    if(!$delay_load)
        google.maps.event.addDomListener(window, 'load', initialize);

    $map_view.bind('reload_map', function(){
        if(!$map){
            initialize();
        }else{
            google.maps.event.trigger($map, "resize");
        }
    });
}


(function($, window){

    $(function($){
        var $body = $('body');
        var $window = $(window);

        if($body.hasClass('home')){
            if($body.hasClass('is-desktop')){
                setTimeout(function(){
                    $.auto_scroll_section({
                        itemSelector: '.content-section, .footer'
                    });
                }, 1000);
            }else if($body.hasClass('is-tablet')){
                if(window.Hammer){

                    $('.content-section, .footer').each(function($i, item){
                        var $hammertime = new Hammer(item, {
                            direction: Hammer.DIRECTION_VERTICAL
                        });

                        $hammertime.get('swipe').set({ direction: Hammer.DIRECTION_VERTICAL });


                        $hammertime.on('swipe', function($e) {
                            $(item).removeClass('start-animation');
                            var $next = $(item).next('.content-section, .footer');

                            if($e.direction == Hammer.DIRECTION_DOWN){
                                $next = $(item).prev('.content-section, .footer');
                            }

                            if($next.length){
                                $next.scrollit({
                                    callback: function(){
                                        $next.addClass('start-animation');
                                    }
                                });
                            }


                        });
                    });

                }
            }

        }

        /*
        $body.bind('full_height_section', function(){
            $('.content-section--full-height').full_height_section();
            $('.content-section--full-height').css('visibility', 'visible');
        }).trigger('full_height_section');


        $window.on('resize.full_height_section', function(){
            $body.trigger('full_height_section');
        });

         */

        $body.on('click', '.scroll-down', function($e){
            var $section = $(this).closest('.content-section');
            var $next = $section.next('.content-section, .section-breadcrumb, .footer');
            if($next.length){
                $next.scrollit({
                    'offset': $('#wpadminbar').height()
                });
            }

            $e.stopPropagation();
            return false;
        });

        $body.on('click', '.scroll-top', function(){
            $('html, body').scrollit();

            return false;
        });

        if($.fn.mCustomScrollbar){
            $('.nav__scroll').mCustomScrollbar();
            $('.scroll-content').mCustomScrollbar();
        }

        $body.on('click', '.btn-nav', function(){
            var $t = $(this);
            var $nav = $('.nav--primary');
            var $html = $('html');
            var $animate_event = "transitionend.nav webkitTransitionEnd.nav oTransitionEnd.nav MSTransitionEnd.nav";

            if($nav.hasClass('nav--opened')){
                $nav.off($animate_event);
                $nav.removeClass('nav--opened');
                $html.removeClass('html--nav-opened');
                $t.removeClass('btn-nav--clicked');
                $('.header__btn-call').removeClass('header__btn-call--menu-opened');
                $('.header__btn-login').removeClass('header__btn-login--menu-opened');
                $nav.focus();
            }else{
                $nav.one($animate_event, function(){
                    $html.addClass('html--nav-opened');
                    $t.addClass('btn-nav--clicked');
                    $('.header__btn-call').addClass('header__btn-call--menu-opened');
                    $('.header__btn-login').addClass('header__btn-login--menu-opened');

                    $('.nav__scroll').mCustomScrollbar('update');
                });
                $nav.addClass('nav--opened');
            }

            return false;
        });

        $body.on('click', '.content-section__notify-btn-close', function(){
            var $parent = $(this).closest('.scrollmagic-pin-spacer');
            var $section = $parent.closest('.content-section');
            var $overlay = $section.find('.content-section__bg-overlay');
            $parent.fadeOut('fast', function(){
                $parent.hide();
            });
            $overlay.fadeOut('fast', function(){
                $parent.hide();
            });

            Cookies.set('section-notify', 1, {path:'/'});

            return false;
        });


        if($.fn.imgpreload){
            $('.full-section').each(function(){
                var $t = $(this);
                $t.find('img').imgpreload({
                    each: function(){
                        $t.full_height_section();
                    }
                });
            });
        }

        $('.js-zoom-slider').tween_zoom_slider({
            duration: 10000
        });

        if(window.ScrollMagic){
            var $controller = new ScrollMagic.Controller();

            $('.js-tween--fade-left').tween_fade_left();
            $('.js-tween--fade-right').tween_fade_right();
            $('.js-tween--fade-out').tween_fade_out();
            $('.js-tween--fade-up').tween_fade_up();
            $('.js-tween--fade-steps').tween_fade_steps();

            if($body.hasClass('is-desktop')){
                $('.js-parallax-background').parallax_background({
                    triggerHook: "onEnter",
                    from: {y: "-30%"},
                    to: {y: "0%"}
                });
            }


            if(!Cookies.get('section-notify')){
                $('.content-section__notify').each(function(){
                    var $t = $(this);
                    var $parent = $t.closest('.content-section');
                    var $overlay = $parent.find('.content-section__bg-overlay');


                    new ScrollMagic.Scene({
                        triggerElement: $parent,
                        duration: 800,
                        offset: -600
                    })
                        .setTween(
                            new TimelineMax({delay: 1})
                                .add(TweenMax.to([$t, $overlay], 1, { opacity: 1, visibility:'visible', ease: Linear.easeNone} ))
                        )
                        .addTo($controller);


                    var $scene_notify_leave = new ScrollMagic.Scene({
                        triggerHook: 'onLeave',
                        triggerElement: $parent,
                        duration: 300,
                        offset: 300
                    });

                    $scene_notify_leave.on('end', function($event){
                        if($event.scrollDirection == 'FORWARD'){
                            $t.css('visibility', 'hidden');
                            $overlay.css('visibility', 'hidden');
                        }else if($event.scrollDirection == 'REVERSE'){
                            $t.css('visibility', 'visible');
                            $overlay.css('visibility', 'visible');
                        }
                    });

                    $scene_notify_leave.setTween(
                            new TimelineMax({delay: 1})
                                .add(TweenMax.to([$t, $overlay], 1, { opacity: 0} ))
                        )
                        .addTo($controller);


                    new ScrollMagic.Scene({
                        triggerHook: 0,
                        triggerElement: $t,
                        duration: 300
                    })
                        .setPin($t)
                        .addTo($controller);
                });
            }

        }

        if($body.hasClass('blog')){
            $('.blog-posts--main').grid_layout();
        }else{
            $('.blog-posts--main').grid_layout({
                filter_selector:false
            });
        }


        $window.on('resize.make_same_height', function(){
            $('.blog-posts').same_children_height('.blog-posts__item-inner');
        }).trigger('resize.make_same_height');

        $window.on('scroll.pin_header', function(){
            var $top = $window.scrollTop();

            if($top > 5){
                $body.addClass('scrolled');
            }else{
                $body.removeClass('scrolled');
            }

        }).trigger('scroll.pin_header');

        $('.people-list').people_popup({
            container_selector: ".people-list",
            person_selector : ".people-list__item",
            open_selector : ".person-view",
            close_selector : ".x-close",
            expand_selector : ".person-expand"
        });

        $('.people-grid').grid_layout({
            "item_selector": ".people-list__item",
            "filter_selector": ".people-filter a",
            "loading_more": 0
        });

        $('.people-filter a').on('click', function($e){
            $('.people-list .people-list__item').trigger('close_popup');
        });

        $('.people-filter__text').on('keypress', function(){
            if(window.__timer_people_search)
                clearTimeout(window.__timer_people_search);

            window.__timer_people_search = setTimeout(function(){
                var $value = $.trim($('.people-filter__text').val()).toLowerCase();

                if(!$value){
                    $('.people-grid').isotope({
                        filter: "*"
                    });

                }else{
                    $('.people-grid').isotope({
                        filter: '[data-title*="'+$value+'"]'
                    });
                }

                $('.people-list .people-list__item').trigger('close_popup');

            }, 300);
        });

        $('.team-list').people_popup({
            container_selector: ".team-list",
            person_selector : ".cf-person",
            open_selector : ".cf-person__url",
            close_selector : ".x-close",
            expand_selector_absolute : ".team-expand-list .person-expand",
            scroll_anchor_selector: ".person-detail",
            before_open_callback: function($group){
                $('.cf-person').trigger('close_popup');
            }
        });

        $('.recommendations-list').people_popup({
            container_selector: ".recommendations-list",
            person_selector : ".cf-person",
            open_selector : "a",
            close_selector : ".x-close",
            expand_selector_absolute : ".recommendations-expand-list .person-expand",
            scroll_anchor_selector: ".person-detail",
            before_open_callback: function($group){
                $('.cf-person').trigger('close_popup');
            }
        });


        $('.contact-view').accordion_sections({
            group_selector:'.contact-view__group',
            toggle_selector:'.contact-view__title, .contact-view__close',
            expand_selector:'.contact-view__detail',
            multi_open : false,
            before_open_callback: function($group){
                $group.prevAll().css('visibility', 'hidden');
                $group.nextAll().css('visibility', 'hidden');
            },
            open_callback: function($group, $options, $e){
                $group.find('.gm-map-view').trigger('reload_map');
                $group.find('.gm-map-view').unbind('reload_map');
                $group.scrollit({
                    offset: 110
                });

            },
            close_callback: function($group){
                $group.prevAll().css('visibility', 'visible');
                $group.nextAll().css('visibility', 'visible');
            }
        });

        $body.on('click', '.lightbox-content .x-close', function(){
            var $lightbox = $(this).closest('.lightbox-content');

            $lightbox.one(TRANSITION_END_EVENT, function(){
                $lightbox.find('.lightbox-content__wrapper').removeClass('active');
            });

            $lightbox.removeClass('active');
            $('html').removeClass('html--lightbox-opened');

            return false;
        });

        $body.on('click touchstart','[data-lightbox]', function(){
            var $t = $(this);
            var $lightbox_id = $t.data('lightbox');
            var $lightbox = $($lightbox_id);

            $lightbox.one(TRANSITION_END_EVENT, function(){
                $('html').addClass('html--lightbox-opened');
            });

            $lightbox.addClass('active');
            $lightbox.find('.lightbox-content__wrapper').addClass('active');

            return false;
        });

        $body.on('click touchstart', '[data-scroll-to]', function(){
            var $to = $(this).data('scroll-to');
            var $container = $($to);

            $container.scrollit();

            return false;
        });

        if($('.documents-accordion').length){
            $('.documents-accordion').accordion_sections({
                "group_selector": ".documents-group",
                "toggle_selector": ".documents-group__title",
                "expand_selector": ".documents-group__list",
                "multi_open": 1,
                "slide_down_duration": 500
            });
        }

    });
})(jQuery, window);
(function($, window){

    TRANSITION_END_EVENT = "transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd";

    SECTION_MIN_HEIGHT = 500;

    var $window = $(window);
    var $body = $('body');

    $.fn.full_height_section = function(){
        var $min_height = $(window).height();

        $(this).each(function($idx, $item){
            var $t = $($item);

            $t.css('min-height', 0);

            var $height = $t.find('.section-content-inner').outerHeight();

            $height = Math.max($min_height, $height, SECTION_MIN_HEIGHT);

            $t.css('min-height', $height);
        });
    };

    $.fn.scrollit = function($options){

        $options = $.extend({
            'offset': 0,
            'speed': 800,
            'callback': false
        }, $options);

        var $t = $(this);
        var $top = $t.offset().top - $options.offset;

        $('html, body').animate({
            'scrollTop': $top
        }, {
            duration: $options.speed,
            always: function(){
                if($options.callback){
                    $options.callback();
                }
            }
        });
    };



    $.fn.tween_fade_steps = function($options){
        if(!window.ScrollMagic){
            return;
        }

        var $controller = new ScrollMagic.Controller();

        this.each(function($idx, $container){
            var $t = $($container);
            var $t_blocks = $t.find('.js-tween__item');
            var $t_texts = $t.find('.js-tween__info');
            var $t_trigger = $t.find('.js-trigger');

            // build tween
            var $tween = new TimelineMax ()
                .add(TweenMax.staggerFromTo($t_blocks, 1, {y:100}, {y: 0, opacity: 1}, 0.5), 0)
                .add(TweenMax.staggerFromTo($t_texts, 1, { opacity: 0}, { opacity: 1 }, 0.4), 1);

            // build scene
            var $scene = new ScrollMagic.Scene({triggerElement: $container, duration: 300})
                .setTween($tween)
                //.addIndicators()
                .addTo($controller);
        });

    };

    $.fn.parallax_object = function($options){

        $options = $.extend({
            triggerElement: false,
            triggerHook: 0,
            from: {y:"0%"},
            to: {y:"30%"},
            duration: "200%",
            offset: 0,
            indicators: 0
        }, $options);

        var $controller = new ScrollMagic.Controller();

        this.each(function($idx, container){
            var $container = $(container);

            var $tween = TweenMax.fromTo($container, 1, $options.from, $options.to);

            var $trigger = $options.triggerElement || $container;

            var $scene = new ScrollMagic.Scene({
                triggerElement: $trigger,
                triggerHook: $options.triggerHook,
                duration: $options.duration,
                offset: $options.offset
            });

            $scene.setTween($tween)
            if($options.indicators)
                $scene.addIndicators();

            $scene.addTo($controller);
        });
    };

    $.fn.parallax_background = function($options){

        this.each(function($idx, container){
            var $container = $(container);
            var $content = $container.find('.js-parallax-background__content');
            $options.triggerElement = $container;
            $content.parallax_object($options);
        });
    };

    $.fn.tween_fade_up = function($options){

        if(!window.ScrollMagic){
            return;
        }

        this.parallax_object({
            triggerHook: "onEnter",
            from: {opacity: 0, y: "50%"},
            to: {opacity: 1, y: "0%"},
            duration: "40%"
        });

        this.parallax_object({
            triggerHook: "onLeave",
            from: {opacity: 1},
            to: {opacity: 0},
            duration: "20%",
            offset: "-100%"
        });

    };

    $.fn.tween_fade_right = function($options){

        if(!window.ScrollMagic){
            return;
        }

        $options = $.extend({
            triggerHook: "onEnter",
            from: {opacity: 0, x: "50%"},
            to: {opacity: 1, x: "0%"},
            duration: "40%"
        }, $options);

        this.parallax_object($options);

    };

    $.fn.tween_fade_left = function($options){

        if(!window.ScrollMagic){
            return;
        }

        $options = $.extend({
            triggerHook: "onEnter",
            from: {opacity: 0, x: "-50%"},
            to: {opacity: 1, x: "0%"},
            duration: "40%"
        }, $options);

        this.parallax_object($options);

    };

    $.fn.tween_fade_out = function(){
        if(!window.ScrollMagic){
            return;
        }

        this.parallax_object({
            triggerHook: "onLeave",
            from: {opacity: 1},
            to: {opacity: 0},
            duration: "20%",
            offset: "-100%"
        });
    };

    $.fn.same_children_height = function($children_selector){
        this.each(function($idx, $container){
            var $children = $($children_selector);
            var $mirror_items = [];
            var $mirror_idx = 0;
            var $previous_item = false;
            $children.each(function($child_idx, $item){
                $item = $($item);
                if($previous_item){
                    var $offset = $item.offset();
                    var $previous_offset = $previous_item.offset();

                    if($previous_offset.top == $offset.top){
                        $mirror_items[$mirror_idx].push($item);
                    }else{
                        $mirror_idx ++;
                        $mirror_items[$mirror_idx] = [$item];
                    }
                }else{
                    $mirror_items[$mirror_idx] = [$item];
                }

                $previous_item = $item;
            });

            $.each($mirror_items, function($idx, $items){
                if($items.length > 1){
                    var $row_height = 0;
                    $.each($items, function($item_idx, $item){
                        var $height = $($item).height();
                        $row_height = Math.max($row_height, $height);
                    });
                    if($row_height){
                        $.each($items, function($item_idx, $item){
                            $($item).css('min-height', $row_height);
                        });
                    }
                }
            });
        });


    };

    $.fn.people_popup = function($options){

        $options = $.extend({
            "person_selector":"",
            "open_selector":"",
            "close_selector":"",
            "expand_selector":"",
            "expand_selector_absolute":"",
            "active_class":"active",
            "scroll_anchor_selector":"",
            "event":"click tap",
            "before_open_callback":false,
            "open_callback":false,
            "before_close_callback":false,
            "close_callback":false
        }, $options);

        var $window = $(window);
        var $document =  $(document);

        this.each(function($idx, container){
            var $container = $(container);
            var $all_people = $container.find($options.person_selector);
            var $all_expander = false;
            if($options.expand_selector){
                $all_expander = $container.find($options.expand_selector);
            }else if($options.expand_selector_absolute){
                $all_expander = $($options.expand_selector_absolute);
            }

            $all_people.each(function($idx, people){
                var $people = $(people);
                var $index = $people.index();
                var $expander = $all_expander.eq($index);

                if(!$expander.length)
                    return true;

                $people.bind('open_popup', function($e){
                    var $me = $(this);
                    var $index = $me.index();

                    if($me.hasClass('no-lightbox')){
                        return true;
                    }

                    if($options.before_open_callback){
                        $options.before_open_callback($me, $e);
                    }

                    $me.addClass($options.active_class);

                    $me.prevAll().trigger('close_popup');
                    $me.nextAll().trigger('close_popup');

                    var $expander = $all_expander.eq($index);
                    var $height = $container.outerHeight();
                    $expander.css('min-height', $height);
                    $expander.fadeIn();

                    var $scroll_anchor = $expander;
                    if($options.scroll_anchor_selector){
                        $scroll_anchor = $($options.scroll_anchor_selector);
                    }

                    $scroll_anchor.scrollit({
                        offset: $('.header').outerHeight() + $('#wpadminbar').outerHeight()
                    });

                    if($options.open_callback){
                        $options.open_callback($me, $e);
                    }

                    /*
                     var $expander_inner = $expander.find('.person-expand__inner');
                     var $offset_top = $window.height()/2 - $expander_inner.height()/2 - $('.header').outerHeight();
                     if($offset_top < 0){
                     $offset_top = 0;
                     }

                     $expander_inner.css('padding-top', $offset_top);
                     */

                });

                $people.bind('close_popup', function($e){
                    var $me = $(this);
                    var $index = $me.index();

                    if($options.before_close_callback){
                        $options.before_close_callback($me, $e);
                    }

                    var $expander = $all_expander.eq($index);

                    $expander.hide();
                    $me.removeClass($options.active_class);

                    if($options.close_callback){
                        $options.close_callback($me, $e);
                    }
                });

                $people.on($options.event, $options.open_selector, function(){
                    var $t = $(this);
                    var $parent = $t.closest($options.person_selector);

                    if($parent.hasClass('no-lightbox')){
                        return true;
                    }

                    $parent.trigger('open_popup');

                    return false;
                });

                $expander.on($options.event, $options.close_selector, function(){
                    var $t = $(this);
                    var $index = $expander.index();
                    var $person = $all_people.eq($index);

                    $person.trigger('close_popup');

                    return false;
                });

                if($people.attr('id') && location.hash == '#!' + $people.attr('id')){
                    $people.trigger('open_popup');
                }
            });

        });

    };

    $.fn.tween_zoom_slider = function($options){

        $options = $.extend({
            duration: 6000
        }, $options);

        this.each(function($idx, container){
            var $container = $(container);
            var $items = $container.find('.js-zoom__item');

            var $position = 0;
            var $do_task = function(){
                var $current_position = $position % $items.length;

                if($position > 0){
                    if($current_position == 0){
                        $items.eq($items.length - 1).addClass('deactive');
                    }else{
                        $items.eq($current_position - 1).addClass('deactive');
                    }
                }


                $items.eq($current_position).removeClass('deactive').addClass('active');

                $position ++;

            };

            $do_task();

            setInterval($do_task, $options.duration);
        });
    };

    $.fn.grid_layout = function($options){

        var $window = $(window);
        var $body = $('body');
        var $posts_page = 2;

        $window.loading_posts = false;

        $options = $.extend({
            "item_selector": ".blog-posts__item",
            "filter_selector": ".blog-filter a",
            "loading_more" : 1
        }, $options);

        this.each(function($idx, container){
            var $grid = $(container);

            if($grid.length){
                if($.fn.isotope){
                    $grid.isotope({
                        itemSelector: $options.item_selector,
                        layoutMode: 'fitRows',
                        fitRows:{
                            gutter: 0
                        }
                    });

                    if($options.filter_selector){
                        $body.on('click', $options.filter_selector, function(){
                            var $filter_key = $(this).data('filter');
                            if(!$filter_key){
                                $grid.isotope({filter: '*'});
                            }else{
                                $grid.isotope({filter: '.' + $filter_key});
                            }

                            return false;
                        });
                    }

                    $grid.find('img').on('load', function(){
                        $grid.isotope('layout');
                    });
                }

                if($options.loading_more){
                    $body.bind('load_more_posts', function(){
                        if($posts_page > $grid.data('pages') || $window.loading_posts){
                            return;
                        }

                        $window.loading_posts = true;

                        $grid.append('<div class="blog-posts__loading"><i class="fa fa-spin fa-spinner"></i></div>');

                        var $paging_url = $grid.data('paging-url');

                        $paging_url = $paging_url.replace('/2/', '/' + $posts_page + '/');

                        $posts_page ++;

                        $.ajax($paging_url,
                            {
                                data: {
                                    'ajax_action': 'load_more_posts'
                                },
                                dataType: 'json'
                            }
                        ).done(function($response){
                                if($response){
                                    if($response.html){
                                        var $new_items = $($response.html);
                                        $grid.append($new_items)

                                        $new_items.on('load', 'img', function(){
                                            $grid.isotope('layout');
                                        });

                                        if($.fn.isotope)
                                            $grid.isotope( 'appended', $new_items );
                                    }

                                    $window.loading_posts = false;
                                }
                            }).fail(function(){
                                $window.unbind('scroll.load_articles');
                            }).always(function(){
                                $('.blog-posts__loading').remove();
                            });


                    });

                    $window.on('scroll.load_articles', function(){
                        if(!$window.loading_posts){
                            var $trigger_top = $(document).height() - $(window).height() - $('.footer').height();
                            if($(window).scrollTop() >= $trigger_top) {
                                $body.trigger('load_more_posts');
                            }
                        }
                    }).trigger('scroll.load_articles');
                }
            }
        });

    };

    $.fn.accordion_sections = function($options){
        $options = $.extend({
            "group_selector": "",
            "toggle_selector": "",
            "expand_selector": "",
            "multi_open": 1,
            "before_open_callback": false,
            "open_callback": false,
            "before_close_callback": false,
            "close_callback": false,
            "slide_down_duration": 1000,
            "slide_up_duration": 500
        }, $options);

        this.each(function($i, container){
            var $container = $(container);
            var $groups = $container.find($options.group_selector);
            var $toggle_buttons = $container.find($options.toggle_selector);

            $groups.bind('active', function($e){
                var $t = $(this);
                $t.addClass('active');

                if($options.before_open_callback){
                    $options.before_open_callback($t, $options, $e);
                }

                $t.find($options.expand_selector).slideDown($options.slide_down_duration, function(){
                    if($options.open_callback){
                        $options.open_callback($t, $options, $e);
                    }
                });
            });

            $groups.bind('deactive', function($e){
                var $t = $(this);

                if($options.before_close_callback){
                    $options.before_close_callback($t, $options, $e);
                }

                $t.find($options.expand_selector).slideUp($options.slide_up_duration, function(){
                    $t.removeClass('active');
                    if($options.close_callback){
                        $options.close_callback($t, $options, $e);
                    }
                });
            });

            $toggle_buttons.on('click', function(){
                var $group = $(this).closest($options.group_selector);

                if(!$options.multi_open){
                    $group.nextAll($options.group_selector).trigger('deactive');
                    $group.prevAll($options.group_selector).trigger('deactive');
                }

                if($group.hasClass('active')){
                    $group.trigger('deactive');
                }else{
                    $group.trigger('active');
                }

                return false;
            });
        });
    };

    $.auto_scroll_section = function($options){
        $options = $.extend({
            itemSelector: ''
        }, $options);

        var $items = $($options.itemSelector);

        $window._isAutoScrolling = false;
        $window._lastScrollTop = $window.scrollTop();

        $window.bind('mousewheel', function(event) {
            if($('html').hasClass('html--nav-opened'))
                return true;

            // event.preventDefault();
//            var scrollTop = this.scrollTop;
//            this.scrollTop = (scrollTop + ((event.deltaY * event.deltaFactor) * -1));
//             console.log(event.deltaY, event.deltaFactor, event.originalEvent.deltaMode, event.originalEvent.wheelDelta);

            if(!$window._isAutoScrolling){
                $window._isAutoScrolling = true;
                var $currentScrollTop = $window.scrollTop();
                var $indicator = $currentScrollTop + $window.height()/2;

                // get direction
                var $direction = 0 < event.deltaY ? 'up':'down';

                $window._lastScrollTop = $currentScrollTop;

                var $current_item = false;

                // find current section
                $items.each(function($item_idx, item){
                    var $item = $(item);
                    var $offset_top = $item.offset().top;
                    var $offset_bottom = $offset_top + $item.height();

                    if($offset_top <= $indicator && $offset_bottom >= $indicator){
                        $current_item = $item;
                        return false;
                    }
                });

                var $next_item = false;
                if($current_item && $current_item.length){
                    $next_item = $current_item.prev($options.itemSelector);
                    if($direction == 'down'){
                        $next_item = $current_item.next($options.itemSelector);
                    }
                }

                if($next_item && $next_item.length){
                    $items.removeClass('start-animation');
                    $next_item.scrollit({
                        offset: $('#wpadminbar').height(),
                        callback: function(){
                            $window._isAutoScrolling = false;
                            $next_item.addClass('start-animation');
                        }
                    });
                }else{
                    $window._isAutoScrolling = false;
                }
            }
        });
    };
})(jQuery, window);